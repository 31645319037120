import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";

// Usuarios page
import ListaUsuarios from "src/pages/Views/ListaUsuarios.vue";
import NuevoUsuario from "src/pages/Views/NuevoUsuario.vue";

// Pages
import Login from "src/pages/Dashboard/Pages/Login.vue";
import RecoverPassword from "src/pages/Dashboard/Pages/RecoverPassword.vue";
import ResetPassword from "src/pages/Dashboard/Pages/ResetPassword.vue";

// Components pages
import Buttons from "src/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "src/pages/Dashboard/Components/GridSystem.vue";
import Panels from "src/pages/Dashboard/Components/Panels.vue";
const SweetAlert = () =>
  import("src/pages/Dashboard/Components/SweetAlert.vue");
import Notifications from "src/pages/Dashboard/Components/Notifications.vue";
import Icons from "src/pages/Dashboard/Components/Icons.vue";
import Typography from "src/pages/Dashboard/Components/Typography.vue";

const CambiarPassword = () =>
  import("src/pages/Views/Franquicia/CambiarContraseña.vue");
const AltaEnvios = () => import("src/pages/Views/AltaEnvios/AltaEnvios.vue");

// Envios
const Envios = () => import("src/pages/Views/Envios/Envios.vue");
const Seguimiento = () => import("src/pages/Views/Seguimiento.vue");

// Clientes
const Clientes = () => import("src/pages/Views/Clientes/Clientes.vue");
const DireccionesHabituales = () =>
  import("src/pages/Views/Clientes/DireccionesHabituales.vue");
const Plantillas = () => import("src/pages/Views/Clientes/Plantillas.vue");

// Cliente
const EditarCliente = () => import("src/pages/Views/Clientes/editCliente.vue");

// Masivos
const Masivos = () => import("src/pages/Views/Masivos/Masivos.vue");

// Valoraciones
const Valoraciones = () =>
  import("src/pages/Views/ControlValoracion/Valoraciones.vue");

// Facturas
const Facturas = () => import("src/pages/Views/Facturacion/ListaFacturas.vue");

// Albaranes
const Albaranes = () => import("src/pages/Views/Albaranes/Albaranes.vue");

// Efectos
const Efectos = () => import("src/pages/Views/Efectos/Efectos.vue");

//Remesas
const Remesas = () => import("src/pages/Views/Remesas/ListaRemesas.vue");
const EditRemesas = () =>
  import("src/pages/Views/Remesas/ModificarRemesas.vue");

// Incidencias
const Incidencias = () => import("src/pages/Views/Incidencias/Incidencias.vue");
const IncidenciaDetalle = () =>
  import("src/pages/Views/DetalleIncidencia/DetalleIncidencia.vue");

// Movilidad
const Trafico = () => import("src/pages/Views/Trafico/Trafico.vue");

// GestionDatos
const MisDatos = () => import("src/pages/Views/GestionDatos/MisDatos.vue");
const Usuarios = () => import("src/pages/Views/GestionDatos/Usuarios.vue");
const Forbidden = () => import("src/pages/Views/NoAuthorizedPage.vue");

//Tarifas
const Tarifas = () => import("src/pages/Views/Tarifas/Tarifas.vue");

// Notificaciones
const Notificaciones = () =>
  import("src/pages/Views/Notificaciones/Notificaciones.vue");
// RMA
const rma = () => import("src/pages/Views/RMA/Rma.vue");
const ImportacionesRma = () =>
  import("src/pages/Views/RMA/ImportacionesRma.vue");

// RECOGIDAS
const Recogidas = () => import("src/pages/Views/Recogidas/Recogidas");

// ENTREGAS
const Entregas = () => import("src/pages/Views/Entregas/Entregas");

// OTRAS PLAZAS
const RecogidasOtrasPlazas = () =>
  import("src/pages/Views/RecogidasOtrasPlazas/RecogidasOtrasPlazas");
const RecogidasProgramadas = () =>
  import("src/pages/Views/RecogidasProgramadas/RecogidasProgramadas");

//OPERATIVA (new)
const EnviosAgrupados = () =>
  import("src/pages/Views/EnviosAgrupados/EnviosAgrupados");

//Documentacion
const Documentacion = () =>
  import("src/pages/Views/Documentacion/Documentacion.vue");

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem,
    },
    {
      path: "panels",
      name: "Panels",
      component: Panels,
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      component: SweetAlert,
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons,
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography,
    },
  ],
};
let usuarios = {
  path: "/usuarios",
  component: DashboardLayout,
  redirect: "/usuarios/listado",
  children: [
    {
      path: "listado",
      name: "Listado Usuarios",
      component: ListaUsuarios,
      props: true,
    },
    {
      path: "nuevousuario",
      name: "NuevoUsuario",
      component: NuevoUsuario,
      props: true,
    },
  ],
};

let recogidas = {
  path: "/recogidas",
  component: DashboardLayout,
  redirect: "/recogidas/pendiente_recogida",
  children: [
    {
      path: "pendiente_recogida",
      name: "Solicitadas",
      component: Recogidas,
      props: true,
    },
    {
      path: "asignada_en_reparto",
      name: "Ptes Recoger",
      component: Recogidas,
      props: true,
    },
    {
      path: "incidencia_recogida",
      name: "Con Incidencia",
      component: Recogidas,
      props: true,
    },
    {
      path: "recogida_finalizada",
      name: "Finalizadas",
      component: Recogidas,
      props: true,
    },
    {
      path: "propias_otras_plazas_recogida",
      name: "Otras Plazas",
      component: Recogidas,
      props: true,
    },
    {
      path: ":retorno/nuevo-envio/:id",
      name: "Nuevo Envío",
      component: AltaEnvios,
      props: true,
    },
    {
      path: ":retorno/nuevo-envio",
      name: "Nuevo Envío",
      component: AltaEnvios,
      props: true,
    },
  ],
};

let entregas = {
  path: "/entregas",
  component: DashboardLayout,
  redirect: "/entregas/en_reparto",
  children: [
    {
      path: "en_reparto",
      name: "Ptes Entregar",
      component: Entregas,
      props: true,
    },
    {
      path: "depositado_en_destino",
      name: "Ptes Asignar",
      component: Entregas,
      props: true,
    },
    {
      path: "incidencia_entrega",
      name: "Con Incidencia",
      component: Entregas,
      props: true,
    },
    {
      path: "entregado",
      name: "Finalizadas",
      component: Entregas,
      props: true,
    },
    {
      path: "propias_otras_plazas_entrega",
      name: "Otras Plazas",
      component: Entregas,
      props: true,
    },
    {
      path: ":retorno/nuevo-envio/:id",
      name: "Nuevo Envío",
      component: AltaEnvios,
      props: true,
    },
    {
      path: ":retorno/nuevo-envio",
      name: "Nuevo Envío",
      component: AltaEnvios,
      props: true,
    },
  ],
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
};

let recoverPage = {
  path: "/recover_password",
  name: "Recuperar Contraseña",
  component: RecoverPassword,
};
let resetPage = {
  path: "/restore_login",
  name: "Restablecer Contraseña",
  component: ResetPassword,
};

let seguimiento = {
  path: "/seguimiento",
  name: "Seguimiento Envio",
  component: Seguimiento,
};
const routes = [
  {
    path: "/",
    redirect: "/franquicia/envios",
  },
  usuarios,
  loginPage,
  recoverPage,
  resetPage,
  componentsMenu,
  seguimiento,
  {
    path: "/franquicia",
    component: DashboardLayout,
    redirect: "/franquicia/clientes",
    children: [
      {
        path: "overview",
        name: "Overview",
        component: Overview,
      },
      {
        path: "clientes",
        name: "Clientes",
        component: Clientes,
        props: true,
      },
      {
        path: "editarCliente/:id",
        name: "Editar Cliente",
        component: EditarCliente,
        props: true,
      },
      {
        path: "envios",
        name: "Envíos",
        component: Envios,
      },
      {
        path: "control",
        name: "Control",
        component: Envios,
      },
      {
        path: "control/nuevo-envio",
        name: "Nuevo Envío",
        component: AltaEnvios,
      },
      {
        path: "trafico/:origen/:tipo/:id/envios",
        name: "Movilidad Envios",
        component: Envios,
      },
      {
        path: "operativa/recogidas/:id/envios",
        name: "Recogida Envios",
        component: Envios,
      },
      {
        path: "importaciones",
        name: "Importaciones Envios",
        component: Masivos,
      },
      {
        path: "nuevo-envio",
        name: "Nuevo Envío",
        component: AltaEnvios,
        props: true,
      },
      {
        path: "nuevo-rma",
        name: "Nuevo RMA",
        component: AltaEnvios,
        props: true,
      },
      {
        path: "plantillas/:id_cliente/nueva-plantilla",
        name: "Nueva Plantilla",
        component: AltaEnvios,
        props: true,
      },
      {
        path: "cambiarcontrasenia",
        name: "NewPassword",
        component: CambiarPassword,
        props: true,
      },
      {
        path: "nuevo-envio/:id",
        name: "Nuevo Envío",
        component: AltaEnvios,
        props: true,
      },
      {
        path: "valoraciones",
        name: "Valoraciones",
        component: Valoraciones,
      },
      {
        path: "direccionesHab/:id",
        name: "Libreta de Contactos",
        component: DireccionesHabituales,
        props: true,
      },
      {
        path: "plantillas/:id",
        name: "Plantillas",
        component: Plantillas,
        props: true,
      },
      {
        path: "facturas",
        name: "Facturas",
        component: Facturas,
        props: true,
      },
      {
        path: "albaranes",
        name: "Albaranes",
        component: Albaranes,
      },
      {
        path: "efectos",
        name: "Efectos",
        component: Efectos,
        props: true,
      },
      {
        path: "remesas",
        name: "Remesas",
        component: Remesas,
        props: true,
      },
      {
        path: "remesa/editremesa/:id",
        name: "Modificar Remesa",
        component: EditRemesas,
        props: true,
      },
      {
        path: "incidencias/:page",
        name: "Incidencias",
        component: Incidencias,
        props: true,
      },
      {
        path: "incidencias/detalle/:id",
        name: "Detalle incidencia",
        component: IncidenciaDetalle,
        props: true,
      },
      {
        path: "trafico",
        name: "Movilidad",
        component: Trafico,
      },
      {
        path: "recogidas",
        name: "Recogidas",
        component: Trafico,
      },
      {
        path: "operativa/recogidas",
        name: "Operativa Recogidas",
        component: Trafico,
      },
      {
        path: "entregas",
        name: "Entregas",
        component: Trafico,
      },
      {
        path: "/franquicia/misDatos",
        name: "Mis Datos",
        component: MisDatos,
        props: true,
      },
      {
        path: "/franquicia/usuarios",
        name: "Usuarios",
        component: Usuarios,
        props: true,
      },
      {
        path: "forbidden",
        name: "Forbidden",
        component: Forbidden,
      },
      {
        path: "/franquicia/notificaciones",
        name: "Notificaciones",
        component: Notificaciones,
        props: true,
      },
      {
        path: "rma",
        name: "RMA",
        component: rma,
      },
      {
        path: "importacionesRma",
        name: "Importaciones RMA",
        component: ImportacionesRma,
      },
      {
        path: "tarifas",
        name: "Tarifas",
        component: Tarifas,
      },
      {
        path: "partner/salidas",
        name: "Salidas",
        component: RecogidasOtrasPlazas,
      },
      {
        path: "partner/salidas-programadas",
        name: "Salidas Programadas",
        component: RecogidasProgramadas,
      },
      {
        path: "envios-agrupados",
        name: "Envíos Agrupados",
        component: EnviosAgrupados,
      },
      {
        path: "documentacion",
        name: "Documentación",
        component: Documentacion,
      },
    ],
  },
  {
    path: "*",
    component: NotFound,
  },
  recogidas,
  entregas,
];

export default routes;
